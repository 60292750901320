import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeModule
      activeModuleContent
      headerIsVisible
      mapboxMode
      baseParams {
        classesViewMode
        activeClassTreeNodeIds
        activeObjectTreeNodeIds
        activeClassesLevelsListItems
        activeClassTreeOptionValue
        buffer
        degradationType
        degradationFragmentationIsolationValue
        degradationCrossingValues {
          degradation_fragmentation_edge_area
          degradation_fragmentation_fragment_size
          degradation_fragmentation_isolation_medium_size
          degradation_fragmentation_isolation_distance
          degradation_fragmentation_isolation_big_size
          degradation_fire_frequency
          degradation_fire_time
          degradation_secondary_vegetation_age
        }
        customViewMode
        customCrossingValues
      }
    }
  }
`;

export const GET_MODULE_DATA = gql`
  query GetModulesData {
    modulesList {
      id
      key
      enabled
      isInBeta
      primaryColor
      secondaryColor
      iconUrl
      displayOrder
			i18nStrings {
				language
				stringValue
      }
			submodules {
        id
        displayOrder
        key
				geospatialObjectCategoryTree {
          id
          key
          geospatialObjectCategoryTreeLevels {
            id
            color
            positionInTree
            level
            parentId
            childrenIds
            i18nStrings {
							stringValue
							language
            }
						geometryCategory {
							i18nStrings {
								stringValue
								language
							}	
						}
          }
          i18nStrings {
						stringValue
						language
          }
        }
        i18nStrings {
					language
					stringValue
        }
      }
    }
  }
`;
