import { gql } from 'graphql.macro';

export const GET_BASE_CLIENT_DATA = gql`
  query {
    app @client {
      activeModule
      activeModuleContent
      showBeforeAndAfterMosaic
      baseParams {
        groupType
        degradationType
        degradationFragmentationIsolationValue
        degradationCrossingValues {
          degradation_fragmentation_edge_area
          degradation_fragmentation_fragment_size
          degradation_fragmentation_isolation_medium_size
          degradation_fragmentation_isolation_distance
          degradation_fragmentation_isolation_big_size
          degradation_fire_frequency
          degradation_fire_time
          degradation_secondary_vegetation_age
          parsedParams
        }
        customViewMode
        customCrossingValues
        territoryType
        territorySubtype
        territoryValueType
        crossingEnabled
        crossingTerritoryType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossingTerritories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        buffer
        yearRange
      }
    }
  }
`;

export const GET_FRAGMENTATION_CLASS_TREES_DATA = gql`
  query GetFragmentationClassTreesData {
    degradation_fragmentation_edge_area: classTreeByKey(key: "degradation_fragmentation_edge_area") {
      id
      key
      i18nStrings {
        language
        stringValue
        __typename
      }
      mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
        i18nStrings {
          language
          stringValue
          __typename
        }
        classTreeNode {
          classe {
            classesTooltips {
              id
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    degradation_fragmentation_fragment_size: classTreeByKey(key: "degradation_fragmentation_fragment_size") {
      id
      key
      i18nStrings {
        language
        stringValue
        __typename
      }
      mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
        i18nStrings {
          language
          stringValue
          __typename
        }
        classTreeNode {
          classe {
            classesTooltips {
              id
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;


export const GET_FIRE_CLASS_TREES_DATA = gql`
  query GetFireClassTreesData {
    degradation_fire_frequency: classTreeByKey(key: "degradation_fire_frequency") {
      id
      key
      i18nStrings {
        language
        stringValue
        __typename
      }
      mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
        i18nStrings {
          language
          stringValue
          __typename
        }
        classTreeNode {
          classe {
            classesTooltips {
              id
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    degradation_fire_time: classTreeByKey(key: "degradation_fire_time") {
      id
      key
      i18nStrings {
        language
        stringValue
        __typename
      }
      mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
        i18nStrings {
          language
          stringValue
          __typename
        }
        classTreeNode {
          classe {
            classesTooltips {
              id
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_SECONDARY_VEGETATION_CLASS_TREES_DATA = gql`
  query GetSecondaryVegetationClassTreesData {
    degradation_secondary_vegetation_age: classTreeByKey(key: "degradation_secondary_vegetation_age") {
      id
      key
      i18nStrings {
        language
        stringValue
        __typename
      }
      mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
        i18nStrings {
          language
          stringValue
          __typename
        }
        classTreeNode {
          classe {
            classesTooltips {
              id
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
