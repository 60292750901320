import React from 'react';
import _ from 'lodash';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ActiveOptionsList from '../ActiveOptionsList';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import getCustomClient from '../../../../../../lib/getCustomClient';

import styles from './CustomClassTreeControl.module.scss';

import {
  GET_BASE_CLIENT_DATA,
  GET_FRAGMENTATION_CLASS_TREES_DATA,
  GET_FIRE_CLASS_TREES_DATA,
  GET_SECONDARY_VEGETATION_CLASS_TREES_DATA,
} from './query';

import { GET_CLASS_TREE_DATA } from '../../../../../../graphql/queries/general';

const mapDataKeyToQuery = {
  fragmentation: GET_FRAGMENTATION_CLASS_TREES_DATA,
  fire: GET_FIRE_CLASS_TREES_DATA,
  secondaryVegetation: GET_SECONDARY_VEGETATION_CLASS_TREES_DATA,
};

const mediumSizeOptions = [
  { groupLabel: 'Isolamento - Alvo', groupKey: 'degradation_fragmentation_isolation_medium_size', id: 25, label: 'maior ou igual a 25ha' },
  { groupLabel: 'Isolamento - Alvo', groupKey: 'degradation_fragmentation_isolation_medium_size', id: 50, label: 'maior ou igual a 50ha' },
  { groupLabel: 'Isolamento - Alvo', groupKey: 'degradation_fragmentation_isolation_medium_size', id: 100, label: 'maior ou igual a 100ha' },
];

const distanceOptions = [
  { groupLabel: 'Isolamento - Distância', groupKey: 'degradation_fragmentation_isolation_distance', id: 5, label: 'maior ou igual a 5km' },
  { groupLabel: 'Isolamento - Distância', groupKey: 'degradation_fragmentation_isolation_distance', id: 10, label: 'maior ou igual a 10km' },
  { groupLabel: 'Isolamento - Distância', groupKey: 'degradation_fragmentation_isolation_distance', id: 20, label: 'maior ou igual a 20km' },
];

const bigSizeOptions = [
  { groupLabel: 'Isolamento - Fonte', groupKey: 'degradation_fragmentation_isolation_big_size', id: 100, label: 'maior ou igual a 100ha' },
  { groupLabel: 'Isolamento - Fonte', groupKey: 'degradation_fragmentation_isolation_big_size', id: 500, label: 'maior ou igual a 500ha' },
  { groupLabel: 'Isolamento - Fonte', groupKey: 'degradation_fragmentation_isolation_big_size', id: 1000, label: 'maior ou igual a 1000ha' },
];

export default function CustomClassTreeControl({
  label,
  dataKey,
  classTreeKey,
  classTreeKeys,
  hideLegendIndex = false,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const client = useApolloClient();
  const formatMessage = useFormatMessage();
  const { data: clientBaseData } = useQuery(GET_BASE_CLIENT_DATA);
  const { data: classTreesData, loading } = useQuery(mapDataKeyToQuery[dataKey], {
    client: getCustomClient('8.0')
  });
  const isFragmentation = dataKey === 'fragmentation';



  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: classTreeKey
    },
  });
  const classTree = _.get(classTreeData, 'classTreeByKey[0]');
  console.log("🚀 ~ HAHAHAHAHAH classTreeData:", classTreeData)


  const baseParams = _.get(clientBaseData, 'app.baseParams');
  const degradationCrossingValues = _.get(baseParams, 'degradationCrossingValues');
  const customCrossingValues = _.get(baseParams, 'customCrossingValues');
  console.log("🚀 ~ customCrossingValues:", customCrossingValues)

  let parsedOptions = _(classTreeKeys)
    .map((classTreeKey) => {
      const classTreeData = _.get(classTreesData, `${ classTreeKey }[0]`);
      const nodes = _.get(classTreeData, 'mvClassTreeLevelsUi');
      const groupLabel = _.chain(classTreeData)
        .get('i18nStrings')
        .find({ language: locale })
        .get('stringValue')
        .value();
      
      return _.map(nodes, (node) => {
        return {
          groupLabel,
          groupKey: classTreeData.key,
          id: node.pixelValue,
          label: _.chain(node)
            .get('i18nStrings')
            .find({ language: locale })
            .get('stringValue')
            .value()
        };
      });
    })
    .flatten()
    .value();

  // TODO: Temp
  // if (isFragmentation) {
  //   parsedOptions = [
  //     ...parsedOptions,
  //     ...mediumSizeOptions,
  //     ...distanceOptions,
  //     ...bigSizeOptions,
  //   ];
  // }

  parsedOptions = _.groupBy(parsedOptions, 'groupLabel');

  const flattenOptions = _.flatten(_.values(parsedOptions));
  const activeOptions = _.compact(_.map(classTreeKeys, (classTreeKey) => {
    const classTreeKeyValue = Number(_.get(degradationCrossingValues, classTreeKey));
    
    if (classTreeKeyValue) {
      return _.find(flattenOptions, (option) => option.groupKey === classTreeKey && option.id === classTreeKeyValue);
    } else {
      return null;
    }
  }));

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const updateClientAppBaseParamsState = (params) => {
    updateClientAppState({
      baseParams: {
        __typename: 'AppBaseParamsState',
        ...params
      }
    });
  };

  const handleSelectChange = (event) => {
    const newValue = `${ classTreeKey }:${ event.target.value }`;

    updateClientAppBaseParamsState({
      customCrossingValues: {
        ...customCrossingValues,
        newValue
      }
    });
  };

  const handleRemove = (value) => {
    const [classTreeKey] = _.split(value, '-');
    updateClientAppBaseParamsState({
      degradationCrossingValues: {
        ...degradationCrossingValues,
        [classTreeKey]: null
      }
    });
  };

  if (loadingClassTreeData || !classTreeData) {
    return (
      <span>Carregando...</span>
    );
  }

  const classTreeLabel = _.chain(classTree)
    .get('i18nStrings')
    .find({ language: locale })
    .get('stringValue')
    .value();

  const options = _.map(_.get(classTree, 'mvClassTreeLevelsUi'), (node) => {
    const prettyIndexOfNodeInTree = _.join(node.positionInTree, '.');
    const nodeLabel = _.chain(node)
      .get('i18nStrings')
      .find({ language: locale })
      .get('stringValue')
      .value();
    const indexText = hideLegendIndex ? '' : `${ prettyIndexOfNodeInTree }. `;

    return {
      id: node.pixelValue,
      label: `${ indexText }${ nodeLabel }`
    };
  });

  return (
    <FormControl
      variant="outlined"
      className={ styles.control }
    >
      <InputLabel id={ `select-${ classTreeKey }` }>{ classTreeLabel }</InputLabel>
      <Select
        labelId={ `select-${ classTreeKey }-label` }
        id={ `select-${ classTreeKey }` }
        value={ '' }
        onChange={ handleSelectChange }
        disabled={ loading }
        placeholder={ formatMessage('mapbiomas.header.base_form.year_range.placeholder') }
      >
        <MenuItem value="" disabled>
          <em>Selecione as opções</em>
        </MenuItem>
        { _.map(_.orderBy(options, 'label', 'asc'), (option) => {
          const isActive = _.get(degradationCrossingValues, option.groupKey) === option.id;

          return (
            <MenuItem
              key={ `${ dataKey }-option-${ option.id }` }
              value={ option.id }
              disabled={ isActive }
            >
              { option.label }
            </MenuItem>
          );
        }) }
      </Select>
    </FormControl>
  );

  return (
    <FormControl
      variant="outlined"
      className={ styles.control }
    >
      <InputLabel id={ `select-${ dataKey }` }>{ label }</InputLabel>
      <Select
        labelId={ `select-${ dataKey }-label` }
        id={ `select-${ dataKey }` }
        value={ '' }
        onChange={ handleSelectChange }
        disabled={ loading }
        placeholder={ formatMessage('mapbiomas.header.base_form.year_range.placeholder') }
      >
        <MenuItem value="" disabled>
          <em>Selecione as opções</em>
        </MenuItem>
        { _.map(parsedOptions, (data, group) => {
          return [
            <ListSubheader className={ styles.groupList }>{ group }</ListSubheader>,
            _.map(data, (option) => {
              const isActive = _.get(degradationCrossingValues, option.groupKey) === option.id;

              return (
                <MenuItem
                  key={ `${ dataKey }-option-${ option.id }` }
                  value={ `${ option.groupKey }-${ option.id }` }
                  disabled={ isActive }
                >
                  { option.label }
                </MenuItem>
              );
            })
          ];
        }) }
      </Select>
      { !_.isEmpty(activeOptions) &&
        <ActiveOptionsList
          categoryKey="groupLabel"
          options={ activeOptions }
          customIdParam={ (option) => `${ option.groupKey }-${ option.id }` }
          onClear={ handleRemove }
        />
      }
    </FormControl>
  );
}
