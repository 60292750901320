import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import DashboardDialogCoverageClassesContent from './content/DashboardDialogCoverageClassesContent';
import DashboardDialogCoverageNaturalOrAnthropicContent from './content/DashboardDialogCoverageNaturalOrAnthropicContent';
import DashboardDialogQualityContent from './content/DashboardDialogQualityContent';
import DashboardDialogTransitionContent from './content/DashboardDialogTransitionContent';
import DashboardDialogPastureQualityContent from './content/DashboardDialogPastureQualityContent';
import DashboardDialogGenericClassesContent from './content/DashboardDialogGenericClassesContent';

import styles from './DashboardDialog.module.scss';

const useStyles = makeStyles((theme) => ({
  yearValue: {
    color: theme.palette.primary.main
  }
}));

export default function DashboardDialog({
  activeModuleContent,
  activeYear,
  baseParams,
  classTreeKey,
  headerIsVisible,
  isOpen,
  onClose = () => {},
  onUpdateClientAppState = () => {},
}) {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setIsExpanded(true);
    }
  }, [isOpen]);

  const classes = useStyles();

  const toggleContent = () => setIsExpanded(!isExpanded);

  const handleTransitionLinkClick = (id) => {
    onUpdateClientAppState({ transitionClassTreeNodeId: id });
    setIsExpanded(false);
  };

  const renderContent = () => {
    switch(activeModuleContent) {
      case 'coverage:coverage_main': {
        const classesViewMode = _.get(baseParams, 'classesViewMode');

        if (classesViewMode === 'class') {
          return (
            <DashboardDialogCoverageClassesContent
              activeYear={ activeYear }
              baseParams={ baseParams }
            />
          );
        } else {
          return (
            <DashboardDialogCoverageNaturalOrAnthropicContent
              activeYear={ activeYear }
              baseParams={ baseParams }
            />
          );
        }
      }
      case 'coverage:coverage_quality': {
        return (
          <DashboardDialogQualityContent
            activeYear={ activeYear }
            baseParams={ baseParams }
          />
        );
      }
      case 'coverage:coverage_changes': {
        return (
          <DashboardDialogTransitionContent
            activeYear={ activeYear }
            baseParams={ baseParams }
            onLinkClick={ handleTransitionLinkClick }
          />
        );
      }
      case 'quality_of_pasture_data:pasture_quality_main': {
        return (
          <DashboardDialogPastureQualityContent
            activeYear={ activeYear }
            baseParams={ baseParams }
          />
        );
      }
      case 'infrastructure:infrastructure_main': {
        return (
          <DashboardDialogGenericClassesContent
            hasObjectFilter
            activeYear={ activeYear }
            baseParams={ baseParams }
          />
        )
      }
      case 'mining:mining_main': {
        return (
          <DashboardDialogGenericClassesContent
            activeYear={ activeYear }
            baseParams={ baseParams }
          />
        )
      }
      case 'urban:urban_transitions': {
        return (
          <DashboardDialogTransitionContent
            activeYear={ activeYear }
            baseParams={ baseParams }
            onLinkClick={ handleTransitionLinkClick }
          />
        );
      }
      default: {
        return (
          <DashboardDialogGenericClassesContent
            activeYear={ activeYear }
            baseParams={ baseParams }
          />
        );
      }
    }
  };

  const territoryIds = _.map(_.get(baseParams, 'territories'), 'id');

  let [moduleKey, contentKey] = _.split(activeModuleContent, ':');
  let titleId;

  if (_.includes(['coverage', 'quality_of_pasture_data'], moduleKey)) {
    titleId = `mapbiomas.dashboard.coverage.${ contentKey }.title`;
  } else {
    titleId = `mapbiomas.dashboard.${ _.snakeCase(moduleKey) }.title`;
  }

  // TODO: Refactor and remove unused components
  if (!_.includes([
    'coverage:coverage_changes',
    // 'urban:urban_transitions'
  ], activeModuleContent)) {
    return null;
  }

  return (
    <Fade in={ isOpen }>
      <div className={ classnames(styles.wrapper, {
        [styles.wrapperWithHeader]: headerIsVisible,
        [styles.wrapperIsCollapsed]: !isExpanded,
      }) }>
        <header className={ styles.header }>
          <h2 className={ styles.title }>
            <span><FormattedMessage id={ titleId } /></span>
          </h2>
          { _.size(territoryIds) > 1 &&
            <p className={ styles.alertInfo }><FormattedMessage id={ `mapbiomas.dashboard.alert.multiple_territories` } /></p>
          }
          <IconButton
            className={ classnames(styles.toggleButton, {
              [styles.toggleButtonIsCollapsed]: isExpanded,
            }) }
            onClick={ toggleContent }
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton className={ styles.closeButton } onClick={ onClose }>
            <CloseIcon />
          </IconButton>
        </header>
        <Collapse in={ isExpanded }>
          { renderContent() }
        </Collapse>
      </div>
    </Fade>
  );
}
