export default {
  groupType: 'territorial',
  degradationType: 'vector',
  degradationFragmentationIsolationValue: null,
  degradationCrossingValues: {
    __typename: 'AppBaseParamsStateDegradationCrossingValues',
    degradation_fragmentation_edge_area: null,
    degradation_fragmentation_fragment_size: null,
    degradation_fragmentation_isolation_medium_size: null,
    degradation_fragmentation_isolation_distance: null,
    degradation_fragmentation_isolation_big_size: null,
    degradation_fire_frequency: null,
    degradation_fire_time: null,
    degradation_secondary_vegetation_age: null,
    parsedParams: null,
  },
  customViewMode: 'category',
  customCrossingValues: [],
  territoryType: null,
  territorySubtype: null,
  territoryValueType: 'single',
  crossingEnabled: false,
  crossingTerritoryType: null,
  crossingTerritories: null,
  territory: null,
  territories: null,
  crossing: 'none',
  classesViewMode: 'class',
  activeClassTreeOptionValue: null,
  activeClassTreeNodeIds: [],
  activeBaseClassTreeNodeIds: [],
  activeObjectTreeNodeIds: [],
  activeSubmodule: null,
  activeClassesLevelsListItems: null,
  activeTransitionsClassesListItems: null,
  activePastureQualityQualityClassesListItems: null,
  yearRange: '1985-2023',
  buffer: 0,
};
